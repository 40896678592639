import React from "react"
import { generateUrl } from "./utils/generateUrl"
import { defaultInstallUrl } from "./utils/installUrl"
import Popup from "./Popup"
import Contents from "./Contents"
import ConfigContext from "./utils/configContext"
import DownloadPrompt from "./DownloadPrompt"

import "../styles/shiny.css"
import "../styles/App.css"
import "../styles/modal.css"

class App extends React.Component {
  static contextType = ConfigContext
  state = {
    initialMount: true,
    featuresVisible: false,
    progress: 0,
    installUrl: defaultInstallUrl,
    isFreeGemsPopupOpen: false,
    isOpen: false,
    showPrompt: false,
    chromeBrowser: false,
  }

  eventTracker = eventSource => {
    if (typeof window !== "undefined") {
      window.fbq &&
        window.fbq("trackCustom", "downloadButtonClicked", {
          click_source: eventSource,
        })
      window.gtag &&
        window.gtag("event", "Download APK", {
          event_category: "Downloaded",
          event_label: eventSource,
          value: 1,
        })
    }
  }

  componentDidMount() {
    // generate onelink url
    this.setState({
      installUrl: generateUrl(this.context.installUrl, this.context.forceUse),
    })

    let self = this
    window.onscroll = function () {
      if (self.state.initialMount && window.pageYOffset > 2) {
        self.setState({ featuresVisible: true, initialMount: false })
      }
    }

    // open free gems popup after 30 seconds
    const freeGemsTimer = setTimeout(() => {
      this.setState({ isFreeGemsPopupOpen: true })
    }, 30 * 1000)
    this.setState({ freeGemsTimer })

    const isChrome = window.chrome && !window.opr
    if (isChrome) {
      this.setState({ chromeBrowser: true })
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.freeGemsTimer)
  }

  closeFreeGemsPopup = () => {
    this.setState({ isFreeGemsPopupOpen: false })
  }

  toggleModal = () => {
    this.setState(state => ({ isOpen: !state.isOpen }))
  }

  togglePrompt = () => {
    this.setState(state => ({ showPrompt: !state.showPrompt }))
  }

  promptCallback = () => {
    this.togglePrompt()
    this.toggleModal()
  }

  render() {
    let { installUrl, isFreeGemsPopupOpen, chromeBrowser } = this.state

    return (
      <>
        {this.state.showPrompt ? (
          <DownloadPrompt
            togglePrompt={this.togglePrompt}
            installUrl={installUrl}
            callback={this.promptCallback}
          />
        ) : null}

        {this.state.isOpen ? (
          <div className="custom-modal video-modal">
            <iframe
              src={`https://player.vimeo.com/video/${this.context.vimeoVideoId}?title=0&byline=0&portrait=0&autoplay=1&loop=1&background=1`}
              width="640"
              height="1138"
              frameBorder="0"
              allowFullScreen={true}
              title="install video"
            />
            <span
              className="is-large"
              onClick={this.toggleModal}
              onKeyDown={this.toggleModal}
              role="button"
              tabIndex="0"
            >
              <strong className="mdi closeBtn">X</strong>
            </span>
          </div>
        ) : null}

        <Contents
          togglePrompt={this.togglePrompt}
          toggleModal={this.toggleModal}
          fbEventAndShowModal={this.eventTracker}
          isChrome={chromeBrowser}
          installUrl={installUrl}
        />

        {isFreeGemsPopupOpen &&
          !this.state.isOpen &&
          !this.state.showPrompt && (
            <Popup
              closeFreeGemsPopup={this.closeFreeGemsPopup}
              togglePrompt={this.togglePrompt}
              toggleModal={this.toggleModal}
              isChrome={chromeBrowser}
              installUrl={installUrl}
              fbEventAndShowModal={this.eventTracker}
            />
          )}
      </>
    )
  }
}

export default App
