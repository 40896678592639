import React, { Component } from "react"
import { Carousel } from "react-responsive-carousel"
import ConfigContext from "./utils/configContext"
import { graphql, StaticQuery } from "gatsby"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const Data = graphql`
  query {
    allGhostPost(
      sort: { order: ASC, fields: published_at }
      filter: { tags: { elemMatch: { slug: { in: "hash-landing_page" } } } }
    ) {
      edges {
        node {
          slug
          title
          html
          excerpt
        }
      }
    }
  }
`

export default class Carousel2 extends Component {
  static contextType = ConfigContext
  render() {
    let { bannerOrder } = this.props
    return (
      <StaticQuery
        query={Data}
        render={data => {
          const imagesArray = [
            ...(this.context.carouselImages || []),
            ...(this.context.variantCarouselImages || []),
          ]

          if (bannerOrder) {
            // if ad_order param present in url,
            // convert the numbers into an array,
            // change the order of imagesArray based on the numberArray.
            const numberArray = Array.from(bannerOrder.toString()).map(Number)
            var numberArrNew = numberArray.map(function (value) {
              return value - 1
            })

            var newImagesArr = []
            for (var i = 0; i < numberArrNew.length; i++) {
              newImagesArr[i] = imagesArray[numberArrNew[i]]
            }

            newImagesArr = newImagesArr.filter(function (element) {
              return element !== undefined
            })

            // checking whether array is empty
            if (newImagesArr.length === 0) {
              newImagesArr = imagesArray
            }
          }
          return (
            <Carousel
              showThumbs={false}
              showArrows={false}
              showStatus={false}
              infiniteLoop
              autoPlay
            >
              {[...(bannerOrder ? newImagesArr : imagesArray)].map(
                (item, i) => (
                  <div key={i} className="carousel-img">
                    <img
                      src={`https://img1.getmegacdn.com/landing-page-images/${item}`}
                      alt="getmega"
                    />
                  </div>
                )
              )}
            </Carousel>
          )
        }}
      />
    )
  }
}
